import { useEffect } from "react";
import OfferteFooter from "../../OfferteFooter";
import { FaCar, FaMapMarkedAlt, FaHandshake, FaUserSecret, FaTools, FaRegClock } from 'react-icons/fa';

function Chauffeur() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    return (
        <div className="chauffeur-container">
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-section-chauffeur"></div>
                    <div className="hero-text-about">
                        <h1>Beveiliging chauffeur</h1>
                    </div>
                </div>

                <p className="intro-text">Onze beveiligingschauffeurs bieden niet alleen vervoersdiensten, maar zorgen ook voor uw veiligheid onderweg. Ontdek hieronder waarom onze chauffeursdiensten uw beste keuze zijn.</p>

                <div className={"service-content"}>
                    <div className="service-card">
                        <FaCar size={30} className="icon-red" />
                        <h2>Veilige Transport</h2>
                        <p>Onze chauffeurs zorgen voor veilig transport van u en uw bezittingen, waarbij ze potentiële veiligheidsrisico's minimaliseren en uw privacy en comfort waarborgen.</p>
                    </div>
                    <div className="service-card">
                        <FaMapMarkedAlt size={30} className="icon-red" />
                        <h2>Routeplanning</h2>
                        <p>We plannen routes zorgvuldig om veilige en efficiënte reizen te garanderen, waarbij we rekening houden met potentiële risico's en verkeersomstandigheden.</p>
                    </div>
                    <div className="service-card">
                        <FaHandshake size={30} className="icon-red" />
                        <h2>Beveiligingsprotocol</h2>
                        <p>Onze chauffeurs zijn getraind in beveiligingsprotocollen en weten hoe ze moeten reageren op noodsituaties en bedreigingen om uw veiligheid te waarborgen.</p>
                    </div>
                    <div className="service-card">
                        <FaUserSecret size={30} className="icon-red" />
                        <h2>Discretie</h2>
                        <p>We handelen met de hoogste mate van discretie en vertrouwelijkheid, waarbij uw persoonlijke en zakelijke informatie altijd wordt beschermd en gerespecteerd.</p>
                    </div>
                    <div className="service-card">
                        <FaTools size={30} className="icon-red" />
                        <h2>Professionele Service</h2>
                        <p>We bieden professionele en hoffelijke service, waarbij we voldoen aan uw specifieke behoeften en verwachtingen om een comfortabele en veilige reiservaring te garanderen.</p>
                    </div>
                    <div className="service-card">
                        <FaRegClock size={30} className="icon-red" />
                        <h2>24/7 Beschikbaarheid</h2>
                        <p>We staan 24/7 paraat om u te voorzien van beveiligde en betrouwbare transportdiensten, ongeacht het tijdstip of de locatie.</p>
                    </div>
                </div>

                <OfferteFooter />
            </div>
        </div>
    );
}

export default Chauffeur;
