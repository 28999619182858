import {
    FaCalendarAlt,
    FaCar,
    FaCarAlt,
    FaDog,
    FaHome,
    FaHotel,
    FaKey,
    FaLock,
    FaRegHospital,
    FaUser
} from "react-icons/fa";
import {IoMdRestaurant} from "react-icons/io";
import {MdDirectionsBoatFilled} from "react-icons/md";

const services = [
    {
        name: "Bewaking met honden",
        description: "Professionele bewaking met getrainde honden om eigendommen te beschermen.",
        icon: <FaDog />
    },
    {
        name: "Chauffeursdiensten",
        description: "Veilige en betrouwbare chauffeursdiensten voor transportbehoeften.",
        icon: <FaCar />
    },
    {
        name: "Evenementsbeveiliging",
        description: "Beveiliging op evenementen om ervoor te zorgen dat alles soepel verloopt.",
        icon: <FaCalendarAlt />
    },
    {
        name: "Horecabeveiliging",
        description: "Beveiligingsservices voor horecagelegenheden om klanten en personeel te beschermen.",
        icon: <IoMdRestaurant />
    },
    {
        name: "Huismeester",
        description: "Diensten voor het beheren en onderhouden van gebouwen en eigendommen.",
        icon: <FaHome />
    },
    {
        name: "Mobile surveillance",
        description: "Flexibele surveillance om te reageren op veranderende beveiligingsbehoeften.",
        icon: <FaCarAlt />
    },
    {
        name: "Objectbeveiliging",
        description: "Beveiligingsservices voor het beschermen van specifieke objecten en locaties.",
        icon: <FaLock />
    },
    {
        name: "Persoonsbeveiliging",
        description: "Individuele beveiliging voor het beschermen van personen tegen potentiële bedreigingen.",
        icon: <FaUser />
    },
    {
        name: "Receptiebeveiliging",
        description: "Beveiligingsdiensten bij recepties om een veilige omgeving te waarborgen.",
        icon: <FaKey />
    },
    {
        name: "Zorgbeveiliging",
        description: "Beveiligingsservices voor zorginstellingen en zorgverleners.",
        icon: <FaRegHospital />
    },
    {
        name: "Hotelbeveiliging",
        description: "Beveiligingsservices voor Hoteldiensten en Hotelmedewerkers",
        icon: <FaHotel />
    },
    {
        name: "Havenbeveiliging",
        description: "Beveiligingsservices bij de Haven",
        icon: <MdDirectionsBoatFilled />
    }
];

export default services;