import { useEffect } from "react";
import OfferteFooter from "../../OfferteFooter";
import { FaGlassMartiniAlt, FaUsers, FaShieldAlt, FaHandsHelping, FaExclamationTriangle, FaUserSecret } from 'react-icons/fa';

function HorecaBeveiliging() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    return (
        <div className="horeca-container">
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-section-horeca"></div>
                    <div className="hero-text-about">
                        <h1>Horeca Beveiliging</h1>
                    </div>
                </div>

                <p className="intro-text">Bescherming in de horeca gaat verder dan alleen fysieke veiligheid. Het draait ook om het creëren van een veilige en gastvrije omgeving voor uw gasten. Ontdek hieronder waarom onze horecabeveiligingsdiensten uw beste keuze zijn.</p>

                <div className="service-content">
                    <div className="service-card">
                        <FaGlassMartiniAlt size={30} className="icon-red" />
                        <h2>Veiligheid bij de Bar</h2>
                        <p>We zorgen voor een veilige omgeving aan de bar, waarbij we helpen bij het voorkomen van incidenten en het handhaven van een verantwoorde drankverkoop.</p>
                    </div>
                    <div className="service-card">
                        <FaUsers size={30} className="icon-red" />
                        <h2>Crowd Management</h2>
                        <p>We beheren de menigte in drukke horecagelegenheden om een soepele doorstroom te garanderen en ervoor te zorgen dat alle gasten veilig en comfortabel kunnen genieten.</p>
                    </div>
                    <div className="service-card">
                        <FaShieldAlt size={30} className="icon-red" />
                        <h2>Toegangscontrole</h2>
                        <p>We handhaven strikte toegangscontrolemaatregelen om alleen geautoriseerde personen toe te laten tot uw horecagelegenheid, waardoor de veiligheid wordt verhoogd.</p>
                    </div>
                    <div className="service-card">
                        <FaHandsHelping size={30} className="icon-red" />
                        <h2>Noodsituatie Assistentie</h2>
                        <p>We bieden snelle en doeltreffende hulp bij noodsituaties, inclusief EHBO en evacuatieprocedures, om ervoor te zorgen dat uw gasten veilig zijn in geval van een incident.</p>
                    </div>
                    <div className="service-card">
                        <FaExclamationTriangle size={30} className="icon-red" />
                        <h2>Conflicthantering</h2>
                        <p>We zijn getraind in het omgaan met verschillende conflictsituaties, van kleine geschillen tot escalaties, om de gemoedsrust van uw gasten te waarborgen.</p>
                    </div>
                    <div className="service-card">
                        <FaUserSecret size={30} className="icon-red" />
                        <h2>Beveiliging van VIP-gasten</h2>
                        <p>We bieden speciale beveiligingsdiensten voor VIP-gasten, waarbij we hun veiligheid en privacy waarborgen tijdens hun verblijf in uw horecagelegenheid.</p>
                    </div>
                </div>

                <OfferteFooter />
            </div>
        </div>
    );
}

export default HorecaBeveiliging;
