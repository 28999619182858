import { useEffect } from "react";
import OfferteFooter from "../../OfferteFooter";
import { FaShip, FaLock, FaBinoculars, FaHardHat, FaShieldAlt, FaExclamationTriangle } from 'react-icons/fa';

function Haven() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    return (
        <div className="haven-container">
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-section-haven"></div>
                    <div className="hero-text-about">
                        <h1>Havenbeveiliging</h1>
                    </div>
                </div>

                <p className="intro-text">Havenbeveiliging is van cruciaal belang voor het beschermen van goederen, schepen en personeel tegen verschillende veiligheidsrisico's. Ontdek hieronder waarom onze havenbeveiligingsdiensten uw beste keuze zijn.</p>

                <div className={"service-content"}>
                    <div className="service-card">
                        <FaShip size={30} className="icon-red" />
                        <h2>Scheepsbeveiliging</h2>
                        <p>We bieden beveiligingsdiensten aan boord van schepen, waarbij we de veiligheid van bemanning, passagiers en lading waarborgen.</p>
                    </div>
                    <div className="service-card">
                        <FaLock size={30} className="icon-red" />
                        <h2>Toegangscontrole</h2>
                        <p>We handhaven strikte toegangscontrolemaatregelen om alleen geautoriseerd personeel en goederen toe te laten tot de havenfaciliteiten.</p>
                    </div>
                    <div className="service-card">
                        <FaBinoculars size={30} className="icon-red" />
                        <h2>Surveillance</h2>
                        <p>We voeren continue surveillance uit op havenfaciliteiten en -activiteiten om verdachte activiteiten te identificeren en te voorkomen.</p>
                    </div>
                    <div className="service-card">
                        <FaHardHat size={30} className="icon-red" />
                        <h2>Veiligheidstraining</h2>
                        <p>We bieden veiligheidstraining aan havenpersoneel om hen voor te bereiden op noodsituaties en om de algemene veiligheidscultuur te verbeteren.</p>
                    </div>
                    <div className="service-card">
                        <FaShieldAlt size={30} className="icon-red" />
                        <h2>Beveiligingsprotocol</h2>
                        <p>We implementeren geavanceerde beveiligingsprotocollen om de veiligheid van havenfaciliteiten te waarborgen en risico's te minimaliseren.</p>
                    </div>
                    <div className="service-card">
                        <FaExclamationTriangle size={30} className="icon-red" />
                        <h2>Noodsituatie Response</h2>
                        <p>We hebben een snel responsmechanisme om effectief te reageren op noodsituaties en bedreigingen in de havenomgeving.</p>
                    </div>
                </div>

                <OfferteFooter />
            </div>
        </div>
    );
}

export default Haven;
