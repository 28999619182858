import React, {useState} from "react";
import {FaSpinner} from 'react-icons/fa';
import services from "./ServicesData";
import emailjs from 'emailjs-com';
import '../Offerte.css';


function Offerte() {
    const SERVICE_ID = "service_4c5u2ob";
    const TEMPLATE_ID = "template_tkfkjaq";

    const sendAutoReply = async () => {
        try {
            await emailjs.send('service_o680fsl', 'template_majblbk', {
                naam: formData.naam,
                email: formData.email,
            }, '4LQ2Uy0AJgYfXF-wC');
        } catch (error) {
            console.error('Fout bij het verzenden van automatisch antwoord:', error);
        }
    };

    const [formData, setFormData] = useState({
        naam: "",
        email: "",
        telefoonnummer: "",
        dienst: "",
        toelichting: "",
    });

    const [submitting, setSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("");

        try {
            setSubmitting(true);
            // Send form data using EmailJS
            await emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, '-u_pgjwJ9weGmd8o9');

            await sendAutoReply();

            setSuccessMessage("We nemen binnenkort contact met u op.");
            setFormData({
                naam: "",
                email: "",
                telefoonnummer: "",
                dienst: "",
                toelichting: "",
            });
        } catch (error) {
            setErrorMessage(error.toString());
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="content-container">
            <div className="hero-section-about">
                <div className="hero-section-evenement"></div>
                <div className="hero-text-about">
                    <h1>Offerte Aanvragen</h1>
                    <p>vul het formulier in om een offerte aan te vragen</p>
                </div>
            </div>

            <form className="offerte-form" onSubmit={handleSubmit}>
                <div className="input-container">
                    <label className="input-label" htmlFor="naam">Naam(*)</label>
                    <input
                        className="input-field"
                        type="text"
                        name="naam"
                        placeholder="Uw naam"
                        value={formData.naam}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="input-container">
                    <label className="input-label" htmlFor="email">Email(*)</label>
                    <input
                        className="input-field"
                        placeholder="Uw email"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="input-container">
                    <label className="input-label" htmlFor="telefoonnummer">Telefoon nummer(*)</label>
                    <input
                        className="input-field"
                        type="tel"
                        placeholder="Uw telefoonnummer"
                        name="telefoonnummer"
                        value={formData.telefoonnummer}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="input-container">
                    <label className="input-label" htmlFor="email">Dienst(*)</label>
                    <select
                        className="input-field"
                        name="dienst"
                        value={formData.dienst}
                        onChange={handleChange}
                        required
                    >
                        <option value="" disabled>Selecteer een dienst</option>
                        {services.map((service, index) => (
                            <option key={index} value={service.name}>{service.name}</option>
                        ))}
                    </select>
                </div>

                <div className="input-container">
                    <label className="input-label" htmlFor="toelichting">Toelichting(*)</label>
                    <textarea
                        className="input-field"
                        placeholder="Uw toelichting"
                        name="toelichting"
                        value={formData.toelichting}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>

                <button className="submit-button" type="submit" disabled={submitting}>
                    {submitting ? <FaSpinner className="spinner-icon"/> : "Versturen"}
                </button>

                {successMessage && <div className="success-message">{successMessage}</div>}
                {errorMessage && <div className="error-message">{errorMessage}</div>}
            </form>
        </div>
    );
}

export default Offerte;
