import { useEffect } from "react";
import OfferteFooter from "../../OfferteFooter";
import { FaLock, FaBullhorn, FaPeopleCarry, FaUserFriends, FaCog, FaRegClock } from 'react-icons/fa';

function Evenement() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    return (
        <div className="evenement-container">
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-section-evenement"></div>
                    <div className="hero-text-about">
                        <h1>Evenement Beveiliging</h1>
                    </div>
                </div>

                <p className="intro-text">Veilige evenementen zijn cruciaal voor het succes en de tevredenheid van zowel organisatoren als deelnemers. Ontdek hieronder waarom onze evenementenbeveiligingsdiensten uw beste keuze zijn.</p>

                <div className={"service-content"}>
                    <div className="service-card">
                        <FaLock size={30} className="icon-red" />
                        <h2>Veiligheidspreventie</h2>
                        <p>We implementeren strategieën om incidenten zoals diefstal, vandalisme en verstoringen proactief te voorkomen, waardoor uw evenement soepel en veilig verloopt.</p>
                    </div>
                    <div className="service-card">
                        <FaBullhorn size={30} className="icon-red" />
                        <h2>Communicatie</h2>
                        <p>Ons team communiceert effectief met zowel de organisatoren als het publiek om een veilige en gestroomlijnde ervaring te garanderen tijdens het evenement.</p>
                    </div>
                    <div className="service-card">
                        <FaPeopleCarry size={30} className="icon-red" />
                        <h2>Begeleiding</h2>
                        <p>We bieden professionele begeleiding en ondersteuning aan deelnemers om ervoor te zorgen dat ze veilig en comfortabel kunnen genieten van het evenement.</p>
                    </div>
                    <div className="service-card">
                        <FaUserFriends size={30} className="icon-red" />
                        <h2>Publieksbeheer</h2>
                        <p>We zorgen voor een veilige en gastvrije omgeving voor uw bezoekers door het effectief beheren van toegangspunten, crowd management en communicatie met het publiek.</p>
                    </div>
                    <div className="service-card">
                        <FaCog size={30} className="icon-red" />
                        <h2>Maatwerkoplossingen</h2>
                        <p>We bieden op maat gemaakte beveiligingsoplossingen die zijn afgestemd op de specifieke behoeften en kenmerken van uw evenement, of het nu gaat om een festival, concert of sportwedstrijd.</p>
                    </div>
                    <div className="service-card">
                        <FaRegClock size={30} className="icon-red" />
                        <h2>Rond-de-klok Bescherming</h2>
                        <p>We staan paraat om uw evenement dag en nacht te beveiligen, zodat u zich kunt concentreren op het succesvol laten verlopen van het evenement, zonder zorgen over de veiligheid.</p>
                    </div>
                </div>
                <OfferteFooter />
            </div>
        </div>
    );
}

export default Evenement;
