import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import services from "./ServicesData";
import OfferteFooter from "../OfferteFooter";
import "../Services.css";

function Services() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    const navigate = useNavigate();

    // Scroll to the top of the page when a service link is clicked
    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div>
            <div className="services-container">
                <h2 className='service-text'>
                    <span className="ds-security">D&S Security</span> biedt een uitgebreid schaal aan <span
                    className="diensten">diensten</span>,
                    waarmee we op maat gemaakte beveiligingsoplossingen kunnen bieden die aansluiten bij uw specifieke
                    behoeften.
                </h2>

                <div className="services-grid">
                    {services.map((service, index) => (
                        <div key={index} className="service-block-wrapper">
                            <Link to={`/Diensten/${service.name.toLowerCase().replace(/\s+/g, '-')}`}
                                  className="nav-link service-block fade-in" onClick={handleClick}>
                                <div className="service-content">
                                    {service.icon}
                                    <h2>{service.name}</h2>
                                    <p>{service.description}</p>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <OfferteFooter />
        </div>
    );
}

export default Services;
