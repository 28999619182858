import React from 'react';
import logo from './Images/SecurityLogo-footer.png';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link from React Router
import services from './Pages/ServicesData'; // Import your services array
import './Footer.css'; // Import the CSS file

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <div className="logo-text">
                        <img src={logo} alt="D&S Security Logo" className="footer-logo"/>
                        <p className="footer-text">
                            <a
                                href="https://www.google.com/maps/search/?api=1&query=Vosmaerstraat+78,+2614+AT+Delft"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="footer-link"
                            >
                                <FaMapMarkerAlt className="footer-icon"/>Vosmaerstraat 78, 2614 AT Delft
                            </a>
                        </p>
                        <div className="footer-contact">
                            <p>
                                <FaPhone className="footer-icon"/> <a href="tel:31 6 11 44 72 64">+31 6 11 44 72 64</a>
                            </p>
                            <p>
                                <FaEnvelope className="footer-icon"/> <a
                                href="mailto:DS-beveiliging@outlook.com">DS-beveiliging@outlook.com</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="footer-section">
                    <h2>Diensten</h2>
                    <ul className="footer-links">
                        {services.map((service, index) => (
                            <li key={index}>
                            <Link
                                    to={`/Diensten/${service.name.toLowerCase().replace(/\s+/g, '-')}`}>{service.name}</Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="footer-section">
                    <h2>Algemeen</h2>
                    <ul className="footer-links-algemeen">
                        <li><Link to="/algemene-voorwaarden">Algemene voorwaarden</Link></li>
                        <li><Link to="/privacy-verklaring">Privacy Verklaring</Link></li>
                        <li><Link to="/Contact">Contact</Link></li>
                        <li><Link to="/OverOns/Missie-Doel">Missie & Doel</Link></li>
                    </ul>
                </div>
            </div>
            <div className="footer-rights">
                <p>© {new Date().getFullYear()} D&S Security. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
