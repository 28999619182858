import { useEffect } from "react";
import OfferteFooter from "../../OfferteFooter";
import { FaShieldAlt, FaSearch, FaHandsHelping, FaUserFriends, FaUserCog, FaClock } from 'react-icons/fa';

function Persoonsbeveiliging() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    return (
        <div className="persoonsbeveiliging-container">
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-section-persoonsbeveiliging"></div>
                    <div className="hero-text-about">
                        <h1>Persoonsbeveiliging Diensten</h1>
                    </div>
                </div>

                <p className="intro-text">Persoonsbeveiliging biedt gepersonaliseerde en discrete beveiligingsoplossingen om individuen te beschermen tegen potentiële dreigingen. Ontdek hieronder waarom onze persoonsbeveiligingsdiensten uw beste keuze zijn.</p>

                <div className={"service-content"}>
                    <div className="service-card">
                        <FaShieldAlt size={30} className="icon-red" />
                        <h2>Risicobeoordeling</h2>
                        <p>We voeren grondige risicobeoordelingen uit om potentiële dreigingen te identificeren en een gepersonaliseerde beveiligingsstrategie te ontwikkelen die aan uw behoeften voldoet.</p>
                    </div>
                    <div className="service-card">
                        <FaSearch size={30} className="icon-red" />
                        <h2>Discrete Bewaking</h2>
                        <p>Onze persoonsbeveiligers opereren discreet en effectief om uw veiligheid te waarborgen, waarbij ze potentiële dreigingen detecteren en voorkomen zonder onnodige aandacht te trekken.</p>
                    </div>
                    <div className="service-card">
                        <FaHandsHelping size={30} className="icon-red" />
                        <h2>24/7 Beschikbaarheid</h2>
                        <p>We staan dag en nacht paraat om uw persoonlijke veiligheid te waarborgen, waarbij we snel kunnen reageren op noodsituaties en onvoorziene omstandigheden.</p>
                    </div>
                    <div className="service-card">
                        <FaUserFriends size={30} className="icon-red" />
                        <h2>Individuele Bescherming</h2>
                        <p>We bieden individuele bescherming op maat, waarbij we uw specifieke behoeften en vereisten in overweging nemen om een veilige omgeving te creëren waarin u zich op uw gemak voelt.</p>
                    </div>
                    <div className="service-card">
                        <FaUserCog size={30} className="icon-red" />
                        <h2>Vertrouwelijke Dienstverlening</h2>
                        <p>Onze persoonsbeveiligers zijn getraind om te werken met een hoog niveau van vertrouwelijkheid en integriteit, waarbij uw privacy te allen tijde wordt gerespecteerd en beschermd.</p>
                    </div>
                    <div className="service-card">
                        <FaClock size={30} className="icon-red" />
                        <h2>Maatwerkoplossingen</h2>
                        <p>We leveren op maat gemaakte persoonsbeveiligingsoplossingen die zijn afgestemd op uw unieke situatie en levensstijl, waarbij we een evenwicht vinden tussen veiligheid en comfort.</p>
                    </div>
                </div>

                <OfferteFooter />
            </div>
        </div>
    );
}

export default Persoonsbeveiliging;
