import { useEffect } from "react";
import OfferteFooter from "../../OfferteFooter";
import { FaKey, FaWrench, FaLock, FaTools, FaBuilding, FaUserTie } from 'react-icons/fa';

function Huismeester() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    return (
        <div className="huismeester-container">
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-section-huismeester"></div>
                    <div className="hero-text-about">
                        <h1>Huismeester Diensten</h1>
                    </div>
                </div>

                <p className="intro-text">Onze huismeesterdiensten bieden een scala aan oplossingen voor het beheer en de veiligheid van residentiële en commerciële gebouwen. Ontdek hieronder waarom onze huismeesterdiensten uw beste keuze zijn.</p>

                <div className="service-content">
                    <div className="service-card">
                        <FaKey size={30} className="icon-red" />
                        <h2>Toegangsbeheer</h2>
                        <p>We beheren en controleren de toegang tot het gebouw, inclusief sleuteluitgifte, toegangscontrolesystemen en bewaking van de ingangen.</p>
                    </div>
                    <div className="service-card">
                        <FaWrench size={30} className="icon-red" />
                        <h2>Onderhoud en Reparaties</h2>
                        <p>We voeren regelmatig onderhoud uit aan het gebouw en de faciliteiten, inclusief reparaties en inspecties om de veiligheid en functionaliteit te waarborgen.</p>
                    </div>
                    <div className="service-card">
                        <FaLock size={30} className="icon-red" />
                        <h2>Beveiligingssystemen</h2>
                        <p>We installeren en onderhouden beveiligingssystemen zoals alarmsystemen, camerasystemen en intercoms voor een optimale beveiliging van het gebouw.</p>
                    </div>
                    <div className="service-card">
                        <FaTools size={30} className="icon-red" />
                        <h2>Facilitair Beheer</h2>
                        <p>We beheren de facilitaire diensten van het gebouw, waaronder schoonmaak, afvalbeheer, en nutsvoorzieningen om een comfortabele en veilige omgeving te garanderen.</p>
                    </div>
                    <div className="service-card">
                        <FaBuilding size={30} className="icon-red" />
                        <h2>Gebouwbeheer</h2>
                        <p>We coördineren en beheren alle aspecten van het gebouw, inclusief administratief beheer, budgettering en communicatie met bewoners of huurders.</p>
                    </div>
                    <div className="service-card">
                        <FaUserTie size={30} className="icon-red" />
                        <h2>Conciërgediensten</h2>
                        <p>We bieden conciërgediensten zoals receptie, postbeheer en klantenservice om de behoeften van bewoners en bezoekers te ondersteunen.</p>
                    </div>
                </div>

                <OfferteFooter />
            </div>
        </div>
    );
}

export default Huismeester;
