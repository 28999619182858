import { useEffect } from "react";
import OfferteFooter from "../../OfferteFooter";
import { FaShieldAlt, FaSearch, FaHandsHelping, FaUserFriends, FaUserCog, FaClock } from 'react-icons/fa';

function ReceptieBeveiliging() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    return (
        <div className="receptiebeveiliging-container">
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-section-receptiebeveiliging"></div>
                    <div className="hero-text-about">
                        <h1>Receptiebeveiliging Diensten</h1>
                    </div>
                </div>

                <p className="intro-text">Receptiebeveiliging is essentieel voor het handhaven van een veilige en gastvrije omgeving voor bezoekers en personeel. Ontdek hieronder waarom onze receptiebeveiligingsdiensten uw beste keuze zijn.</p>

                <div className={"service-content"}>
                    <div className="service-card">
                        <FaShieldAlt size={30} className="icon-red" />
                        <h2>Professionele Beveiliging</h2>
                        <p>Onze getrainde beveiligingsmedewerkers zorgen voor een veilige omgeving bij de receptie, waarbij ze potentiële risico's identificeren en proactief aanpakken.</p>
                    </div>
                    <div className="service-card">
                        <FaSearch size={30} className="icon-red" />
                        <h2>Toegangscontrole</h2>
                        <p>We implementeren strikte toegangscontrolemaatregelen om ongeautoriseerde personen te weren en alleen geautoriseerde bezoekers en personeel toe te laten tot het gebouw.</p>
                    </div>
                    <div className="service-card">
                        <FaHandsHelping size={30} className="icon-red" />
                        <h2>Klantvriendelijkheid</h2>
                        <p>We streven naar een evenwicht tussen veiligheid en gastvrijheid, waarbij onze medewerkers vriendelijk en professioneel omgaan met bezoekers en personeel bij de receptie.</p>
                    </div>
                    <div className="service-card">
                        <FaUserFriends size={30} className="icon-red" />
                        <h2>Bezoekersbegeleiding</h2>
                        <p>We bieden ondersteuning en begeleiding aan bezoekers bij de receptie, waardoor ze zich welkom en veilig voelen bij aankomst in het gebouw.</p>
                    </div>
                    <div className="service-card">
                        <FaUserCog size={30} className="icon-red" />
                        <h2>Aanpasbare Oplossingen</h2>
                        <p>We leveren op maat gemaakte receptiebeveiligingsoplossingen die zijn afgestemd op de specifieke behoeften en vereisten van uw organisatie.</p>
                    </div>
                    <div className="service-card">
                        <FaClock size={30} className="icon-red" />
                        <h2>Continue Bewaking</h2>
                        <p>Onze receptiebeveiliging biedt continue bewaking en ondersteuning, zodat u er zeker van kunt zijn dat uw receptie te allen tijde goed beveiligd is.</p>
                    </div>
                </div>

                <OfferteFooter />
            </div>
        </div>
    );
}

export default ReceptieBeveiliging;
