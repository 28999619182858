import { useEffect } from "react";
import '../About.css';
import OfferteFooter from "../OfferteFooter";

function About() {
    useEffect(() => {
        document.title = "Over Ons | D&S Security";
    });

    return (
        <div>
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-background-about"></div>
                    <div className="hero-text-about">
                        <h1>Ontdek Wie Wij Zijn</h1>
                    </div>
                </div>

                <div className='CompanyOverviewSection'>
                    <h2>Wie Zijn Wij?</h2>
                    <p>Welkom bij <strong>D&S Security</strong>! Wij zijn een energiek en vooruitstrevend beveiligingsbedrijf dat zich richt op het leveren van slimme en betrouwbare beveiligingsoplossingen voor zowel particulieren als bedrijven. Ons doel? Het creëren van een veilige omgeving waar jij je zorgeloos kunt voelen.</p>
                    <p>We werken nauw samen met onze klanten om oplossingen te vinden die écht bij hun behoeften passen. Of je nu een klein bedrijf bent of een groot bedrijf met complexe eisen, wij hebben de expertise en de drive om de beste oplossing voor jou te vinden.</p>
                    <p>Bij <strong>D&S Security</strong> draait alles om vertrouwen, innovatie en klanttevredenheid. Wij zijn gepassioneerd door veiligheid en streven er elke dag naar om de lat hoger te leggen in de beveiligingsbranche. Ons team van professionals staat klaar om jouw beveiligingsdoelen te helpen realiseren en je gemoedsrust te bieden, van het kleinste alarm tot de meest geavanceerde systemen.</p>
                </div>
            </div>
            <OfferteFooter />
        </div>
    );
}

export default About;
