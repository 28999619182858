import { useEffect } from "react";
import OfferteFooter from "../../OfferteFooter";
import { FaCar, FaShieldAlt, FaSearch, FaUserFriends, FaCamera, FaMapMarkerAlt } from 'react-icons/fa';

function MobileSurveillance() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    return (
        <div className="mobile-surveillance-container">
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-section-mobile-surveillance"></div>
                    <div className="hero-text-about">
                        <h1>Mobiele Surveillance Diensten</h1>
                    </div>
                </div>

                <p className="intro-text">Onze mobiele surveillance biedt een effectieve oplossing voor het bewaken van grote gebieden en het snel reageren op verdachte activiteiten. Ontdek hieronder waarom onze mobiele surveillance uw beste keuze is.</p>

                <div className="service-content">
                    <div className="service-card">
                        <FaCar size={30} className="icon-red" />
                        <h2>Flexibel en Mobiel</h2>
                        <p>We bieden mobiele surveillance met voertuigen die snel kunnen reageren op verschillende locaties en situaties, waardoor een breed scala aan gebieden kan worden bewaakt.</p>
                    </div>
                    <div className="service-card">
                        <FaShieldAlt size={30} className="icon-red" />
                        <h2>Bescherming van Eigendommen</h2>
                        <p>We beschermen uw eigendommen tegen diefstal, inbraak en vandalisme door regelmatige patrouilles en visuele afschrikking.</p>
                    </div>
                    <div className="service-card">
                        <FaSearch size={30} className="icon-red" />
                        <h2>Snelle Respons</h2>
                        <p>We reageren snel op verdachte activiteiten en noodsituaties, waardoor schade en verlies tot een minimum worden beperkt.</p>
                    </div>
                    <div className="service-card">
                        <FaUserFriends size={30} className="icon-red" />
                        <h2>Samenwerking met Gemeenschap</h2>
                        <p>We werken samen met de lokale gemeenschap en wetshandhavingsinstanties om de veiligheid in de omgeving te verbeteren en criminaliteit te verminderen.</p>
                    </div>
                    <div className="service-card">
                        <FaCamera size={30} className="icon-red" />
                        <h2>Geavanceerde Technologie</h2>
                        <p>We maken gebruik van geavanceerde surveillanceapparatuur, zoals camerasystemen en GPS-tracking, om een nauwkeurige monitoring van gebieden te garanderen.</p>
                    </div>
                    <div className="service-card">
                        <FaMapMarkerAlt size={30} className="icon-red" />
                        <h2>GPS-tracking</h2>
                        <p>We gebruiken GPS-tracking om real-time locatiegegevens van onze voertuigen te volgen, waardoor een efficiënte en effectieve bewaking van grote gebieden mogelijk is.</p>
                    </div>
                </div>

                <OfferteFooter />
            </div>
        </div>
    );
}

export default MobileSurveillance;
