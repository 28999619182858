import React, { useEffect } from "react";
import './ServiceCards.css';
import { FaShieldAlt, FaSearch, FaHandsHelping, FaUserFriends, FaUserCog, FaClock } from 'react-icons/fa';
import OfferteFooter from "../../OfferteFooter";

function HondBewaking() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    return (
        <div className="hond-bewaking-container">
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-section-dog"></div>
                    <div className="hero-text-about">
                        <h1>Bewaking met honden</h1>
                    </div>
                </div>

                <p className="intro-text">Bewaking met honden biedt een unieke en effectieve beveiligingsoplossing.
                    Honden worden al eeuwenlang ingezet voor bewakingsdoeleinden vanwege hun scherpe zintuigen,
                    trouw en afschrikkend vermogen. Ontdek hieronder waarom onze hondbewakingsdienst uw beste keuze
                    is.</p>
                <div className={"service-content"}>

                    <div className="service-card">
                        <FaShieldAlt size={30} className="icon-red" />
                        <h2>Afschrikkend effect</h2>
                        <p>Honden hebben van nature een afschrikkende werking op indringers. De aanwezigheid van een
                            bewakingshond kan potentiële indringers ontmoedigen om uw eigendom binnen te dringen.</p>
                    </div>
                    <div className="service-card">
                        <FaSearch size={30} className="icon-red" />
                        <h2>Verbeterde detectie</h2>
                        <p>Met hun sterke reukvermogen en gehoor kunnen bewakingshonden verdachte activiteiten
                            detecteren die mogelijk aan de aandacht van elektronische beveiligingssystemen
                            ontsnappen.</p>
                    </div>
                    <div className="service-card">
                        <FaHandsHelping size={30} className="icon-red" />
                        <h2>Snelle respons</h2>
                        <p>Honden reageren snel op dreigingen, waardoor ze een effectieve eerste verdedigingslinie
                            vormen. Ze kunnen onmiddellijk ingrijpen om uw eigendom te beschermen in geval van een
                            noodsituatie.</p>
                    </div>
                    <div className="service-card">
                        <FaUserFriends size={30} className="icon-red" />
                        <h2>Flexibiliteit</h2>
                        <p>Bewakingshonden kunnen in verschillende omgevingen worden ingezet, van woningen tot
                            commerciële panden. Ze zijn flexibel inzetbaar en kunnen zich aanpassen aan verschillende
                            beveiligingsscenario's.</p>
                    </div>
                    <div className="service-card">
                        <FaUserCog size={30} className="icon-red" />
                        <h2>Menselijke interactie</h2>
                        <p>Bewakingshonden worden getraind om opdrachten van hun handlers uit te voeren, waardoor ze
                            effectief kunnen samenwerken met menselijk beveiligingspersoneel om uw eigendom te
                            beschermen.</p>
                    </div>
                    <div className="service-card">
                        <FaClock size={30} className="icon-red" />
                        <h2>24/7 bescherming</h2>
                        <p>Met een bewakingshond krijgt u continue bescherming, zelfs 's nachts of in afgelegen gebieden
                            waar elektronische beveiligingssystemen mogelijk niet toereikend zijn.</p>
                    </div>
                </div>
                <OfferteFooter/>
            </div>
        </div>
    );
}

export default HondBewaking;
