import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavBar.css';
import logo from './Images/SecurityLogo-Main.png';
import { FaPhone } from 'react-icons/fa';
import { IoIosPaper } from "react-icons/io";

function NavBar() {
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <>
            <nav>
                <div className="navbar-container">
                    <div className="logo-container">
                        <a href="/" aria-label="Go to homepage">
                            <img src={logo} alt="logo van d&s security" width={250} height={120}/>
                        </a>
                    </div>
                    <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
                        <li className="nav-item">
                            <Link to="/" className={`nav-link ${isActive('/')}`} onClick={closeMenu}>
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/OverOns" className={`nav-link ${isActive('/OverOns')}`} onClick={closeMenu}>
                                Over Ons
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Diensten" className={`nav-link ${isActive('/Diensten')}`} onClick={closeMenu}>
                                Diensten
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Contact" className={`nav-link ${isActive('/Contact')}`} onClick={closeMenu}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                    <div className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={toggleMenu} aria-label="Toggle navigation menu">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>

                    <div className="additional-info">
                        <a href="tel:31611447264" className="phone-number">
                            <FaPhone/> +31 6 11 44 72 64
                        </a>
                        <span className="divider">|</span>
                        <Link to="/Offerte" className="offerte-link">
                            <IoIosPaper/> Offerte Aanvragen
                        </Link>
                    </div>
                </div>
            </nav>

            {/* Overlay for darkening content */}
            {menuOpen && <div className="overlay open" onClick={closeMenu}></div>}
        </>
    );
}

export default NavBar;
