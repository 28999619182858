import React, {useEffect, useState} from "react";
import '../App.css';
import '../Home.css'
import OfferteFooter from "../OfferteFooter";
import {FaUserShield, FaShieldAlt, FaLock} from 'react-icons/fa';


function Home() {
    useEffect(() => {
        document.title = "Home | D&S Security";

        // Log the eagle with company name in console
        console.log(`
                                                           
                                                                                                    
                        ..          -#######*-.         -###*.              :#####*:                
                        ==.         -###########-      *######-           :##########*.             
                      .##*=         -##+     .*###     ###:=##+           ###.    .+=               
          .-+#.     .*#####::       -##+       =##+.   :#####*..*#.       *###:                     
           .:*#*   .+######*=.      -##+       .###.   :#####*.=##=       .+#######*.               
           .:-##*. .#######         -##+       :##*. .*###-+######:           .-+#####.             
               +#*.#######=         -##+      .###+  *##+.   ####+        ..      .*##-             
               -#*=#######          -##+   .-####-   +##*. +#######-     -###+.  .=###:             
               :#########:          -##########=     .+######*.  *###-    .*########*               
             .*######*.             ........            .--.      ......      .:::                  
             .= .+*#####-                                                                           
                   -*#=###-               :-:  -::. .--:  :. .. -:-. ...:-::.:  :.                  
                .-==.+.+**##.            .-.   :    :     :. .. = .- ..  :    ::.                   
                      .     .:           .. :  :    :  .- :. .. = :. ..  :    ..                    
                                                                                                                                                                                          
  `);

    });


    const navigateTo = (path) => {
        window.location.href = path;
    };

    const [hoveredReason, setHoveredReason] = useState(null);

    const reasons = [
        {
            icon: <FaUserShield className="icon" />,
            title: "Ervaren Personeel",
            description: "Ons team bestaat uit goed getrainde en ervaren beveiligingsprofessionals die uw eigendommen met de grootst mogelijke zorg beschermen."
        },
        {
            icon: <FaShieldAlt className="icon" />,
            title: "Maatwerkoplossingen",
            description: "Wij bieden op maat gemaakte beveiligingsoplossingen die zijn afgestemd op de specifieke behoeften van uw bedrijf of evenement, zodat u precies krijgt wat u nodig heeft."
        },
        {
            icon: <FaLock className="icon" />,
            title: "Snelle Respons",
            description: "Bij eventuele incidenten staan wij garant voor een snelle en efficiënte respons, waardoor u gemoedsrust heeft en uw eigendommen in veilige handen zijn."
        }
    ];

    return (
        <div className="home-container">
            <section className="hero-section">
                <div className="hero-background"></div>
                <div className="hero-content">
                    <h1>D&S SECURITY: Uw Partner in Veiligheid en Bescherming</h1>
                    <p className="hero-description">D&S Security staat garant voor uitgebreide fysieke beveiliging.
                        Onze oplossingen zijn afgestemd op uw behoeften, voor totale gemoedsrust.
                        Vertrouw op ons voor betrouwbare beveiliging, altijd en overal.</p>
                    <div className="button-container">
                        <button className="button services-button" onClick={() => navigateTo("/OverOns")}>Bekijk Over
                            Ons
                        </button>
                        <button className="button contact-button" onClick={() => navigateTo("/Diensten")}>Bekijk onze
                            diensten
                        </button>
                    </div>
                </div>
            </section>


            <section className="about-section">
                <div className="section-content">
                    <h2>Welkom bij D&S Security</h2>
                    <p>Als jonge en dynamische onderneming staat D&S Security voor vernieuwing en vooruitgang in de
                        beveiligingssector. Hoewel we nog maar net zijn begonnen, hebben we een frisse benadering van
                        beveiliging, gedreven door innovatie en klantgerichtheid. Onze missie is om op maat gemaakte
                        beveiligingsoplossingen te bieden die voldoen aan de specifieke behoeften van onze klanten,
                        waarbij we streven naar de hoogste kwaliteit en betrouwbaarheid.</p>
                    <button className="button contact-button" onClick={() => navigateTo("/Contact")}>Neem Contact Op
                    </button>
                </div>
            </section>

            <section className="quote-section">
                <div className="quote-content">
                    <h1>Bekijk Over Onze  <span className="gray-text">Missie & Doel</span></h1>
                    <button className="button sectionButton" onClick={() => navigateTo("/OverOns/Missie-Doel")}>Missie
                        & Doel
                    </button>
                </div>
            </section>

            <section className="why-choose-section" style={{backgroundColor: "#f2f2f2", color: "#333"}}>
                <h2>Waarom kiezen voor D&S Security?</h2>
                <div className="reasons-container">
                    {reasons.map((reason, index) => (
                        <div
                            className="why-choose-reason"
                            key={index}
                            onMouseEnter={() => setHoveredReason(index)}
                            onMouseLeave={() => setHoveredReason(null)}
                            style={{
                                backgroundColor: hoveredReason === index ? "#a10202" : "#fff",
                                boxShadow: hoveredReason === index ? "0px 4px 8px rgba(0, 0, 0, 0.2)" : "none",
                            }}
                        >
                            <div className="icon-container">
                                {reason.icon}
                            </div>
                            <div className="reason-info">
                                <p className="reason-title">{reason.title}</p>
                                <p className="reason-description">{hoveredReason === index ? reason.description : ''}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>


            <OfferteFooter/>
        </div>
    );
}

export default Home;
