import React from 'react';

const OfferteFooter = () => {
    const navigateTo = (path) => {
        window.location.href = path;
    };

    return (
        <section className="quote-section">
            <div className="quote-content">
                <h1>Vraag een <span className="gray-text">Offerte</span> Aan</h1>
                <button className="button sectionButton" onClick={() => navigateTo("/Offerte")}>Offerte Aanvragen
                </button>
            </div>
        </section>

    );
}

export default OfferteFooter;