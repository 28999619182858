import React, {useState, useEffect} from "react";
import {CiLocationOn} from "react-icons/ci";
import {FiPhone} from "react-icons/fi";
import {FaBuilding} from "react-icons/fa6";
import {FaSpinner} from 'react-icons/fa';
import emailjs from 'emailjs-com';
import OfferteFooter from "../OfferteFooter";
import MapContainer from '../MapContainer';

function Contact() {
    useEffect(() => {
        document.title = "Contact | D&S Security";
    });

    const [formData, setFormData] = useState({
        naam: "",
        email: "",
        telefoonnummer: "",
        vraag: "",
    });

    const [submitting, setSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const sendAutoReply = async () => {
        try {
            await emailjs.send('service_o680fsl', 'template_majblbk', {
                naam: formData.naam,
                email: formData.email,
            }, '4LQ2Uy0AJgYfXF-wC');
        } catch (error) {
            console.error('Fout bij het verzenden van automatisch antwoord:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("");

        try {
            setSubmitting(true);

            await emailjs.sendForm('service_4c5u2ob', 'template_7yjzf5d', e.target, '-u_pgjwJ9weGmd8o9');


            await sendAutoReply();

            setSuccessMessage("We nemen binnenkort contact met u op.");
            setFormData({
                naam: "",
                email: "",
                telefoonnummer: "",
                vraag: "",
            });
        } catch (error) {
            setErrorMessage(error.toString());
        } finally {
            setSubmitting(false);
        }
    };


    return (
        <div>
            <div className="content-container">
                <MapContainer/>

                <div className="company-contact-container">
                    <div className="company-addres">
                        <div className="icon-container">
                            <CiLocationOn color="red" fontSize="3em"/>
                        </div>
                        <div className="text-container">
                            <h2>Adres Gegevens</h2>
                            <p>Vosmaerstraat 78</p>
                            <p>2614 AT Delft</p>
                        </div>
                    </div>
                    <div className="company-contact">
                        <div className="icon-container">
                            <FiPhone color="red" fontSize="3em"/>
                        </div>
                        <div className="text-container">
                            <h2>Contact Gegevens</h2>
                            <p><a href="tel:31611447264" className="contact-link">+31 6 11 44 72 64</a></p>
                            <p><a href="mailto:DS-beveiliging@outlook.com"
                                  className="contact-link">DS-beveiliging@outlook.com</a></p>
                        </div>
                    </div>
                    <div className="company-info">
                        <div className="icon-container">
                            <FaBuilding color="red" fontSize="3em"/>
                        </div>
                        <div className="text-container">
                            <h2>Bedrijf Gegevens</h2>
                            <p>KVK: 93744196</p>
                            <p></p>
                        </div>
                    </div>
                </div>

                <div className="contact-container">
                    <div className="contact-text-container">
                        <h1>Neem contact met ons op</h1>
                        <p>Heeft u vragen over onze diensten of heeft u een andere vraag? vul het formulier in en wij
                            zullen zo spoedig mogelijk met u contact opnemen</p>
                    </div>


                    <form className="contact-input-container" onSubmit={handleSubmit}>
                        <input
                            className="contact-input"
                            placeholder="Naam"
                            type="text"
                            name="naam"
                            value={formData.naam}
                            onChange={handleChange}
                            required
                        />

                        <input
                            className="contact-input"
                            placeholder="Email"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />

                        <input
                            className="contact-input"
                            placeholder="Telefoon nummer"
                            type="tel"
                            name="telefoonnummer"
                            value={formData.telefoonnummer}
                            onChange={handleChange}
                            required
                        />

                        <textarea
                            className="textarea contact-input"
                            placeholder="Uw vraag/opmerking"
                            name="vraag"
                            value={formData.vraag}
                            onChange={handleChange}
                            required
                        ></textarea>

                        <button className="contact-submit" type="submit" disabled={submitting}>
                            {submitting ? <FaSpinner className="spinner-icon"/> : "Versturen"}
                        </button>

                        {successMessage && <div className="success-message">{successMessage}</div>}
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                    </form>
                </div>
                <OfferteFooter />
            </div>
        </div>
    );
}

export default Contact;
