import { useEffect } from "react";
import OfferteFooter from "../../OfferteFooter";
import { FaBed, FaUserShield, FaCamera, FaHandsHelping, FaShieldAlt, FaLock } from 'react-icons/fa';

function Hotelbeveiliging() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    return (
        <div className="hotelbeveiliging-container">
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-section-hotel"></div>
                    <div className="hero-text-about">
                        <h1>Hotelbeveiliging</h1>
                    </div>
                </div>

                <p className="intro-text">Veiligheid in hotels is van cruciaal belang voor het welzijn en de tevredenheid van gasten en personeel. Ontdek hieronder waarom onze hotelbeveiligingsdiensten uw beste keuze zijn.</p>

                <div className="service-content">
                    <div className="service-card">
                        <FaBed size={30} className="icon-red" />
                        <h2>Kamerveiligheid</h2>
                        <p>We waarborgen de veiligheid van gastenkamers door middel van regelmatige patrouilles, controle van beveiligingssystemen en assistentie bij noodgevallen.</p>
                    </div>
                    <div className="service-card">
                        <FaUserShield size={30} className="icon-red" />
                        <h2>Beveiliging Personeel</h2>
                        <p>We bieden bescherming en ondersteuning aan hotelmedewerkers, inclusief training in omgang met beveiligingsincidenten en preventie van geweld.</p>
                    </div>
                    <div className="service-card">
                        <FaCamera size={30} className="icon-red" />
                        <h2>Surveillance</h2>
                        <p>We installeren en monitoren camerasystemen in het hele hotel om verdachte activiteiten te detecteren en de veiligheid van gasten en personeel te waarborgen.</p>
                    </div>
                    <div className="service-card">
                        <FaHandsHelping size={30} className="icon-red" />
                        <h2>Noodhulp</h2>
                        <p>We bieden snelle respons en hulp bij noodsituaties zoals brandalarmen, medische noodgevallen en evacuaties om de veiligheid van alle aanwezigen te waarborgen.</p>
                    </div>
                    <div className="service-card">
                        <FaShieldAlt size={30} className="icon-red" />
                        <h2>Preventieve Maatregelen</h2>
                        <p>We implementeren preventieve maatregelen zoals identiteitscontroles, toegangsbeperkingen en veiligheidstraining om de veiligheid in het hotel te maximaliseren.</p>
                    </div>
                    <div className="service-card">
                        <FaLock size={30} className="icon-red" />
                        <h2>Beveiliging Faciliteiten</h2>
                        <p>We beveiligen hotelvoorzieningen zoals zwembaden, fitnessruimtes en parkeergarages om ervoor te zorgen dat gasten veilig kunnen genieten van alle faciliteiten.</p>
                    </div>
                </div>

                <OfferteFooter />
            </div>
        </div>
    );
}

export default Hotelbeveiliging;
