import { useEffect } from "react";
import OfferteFooter from "../../OfferteFooter";
import { FaShieldAlt, FaSearch, FaHandsHelping, FaUserFriends, FaUserCog, FaClock } from 'react-icons/fa';

function ObjectBeveiliging() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    return (
        <div className="objectbeveiliging-container">
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-section-objectbeveiliging"></div>
                    <div className="hero-text-about">
                        <h1>Objectbeveiliging Diensten</h1>
                    </div>
                </div>

                <p className="intro-text">Objectbeveiliging is van vitaal belang om de veiligheid en integriteit van eigendommen te waarborgen. Ontdek hieronder waarom onze objectbeveiligingsdiensten uw beste keuze zijn.</p>

                <div className={"service-content"}>
                    <div className="service-card">
                        <FaShieldAlt size={30} className="icon-red" />
                        <h2>Risicoanalyse</h2>
                        <p>We voeren uitgebreide risicoanalyses uit om potentiële bedreigingen voor uw objecten te identificeren en gepaste beveiligingsmaatregelen te implementeren.</p>
                    </div>
                    <div className="service-card">
                        <FaSearch size={30} className="icon-red" />
                        <h2>Surveillance</h2>
                        <p>Onze beveiligingsmedewerkers houden objecten continu in de gaten, waarbij ze verdachte activiteiten detecteren en proactief ingrijpen om risico's te minimaliseren.</p>
                    </div>
                    <div className="service-card">
                        <FaHandsHelping size={30} className="icon-red" />
                        <h2>Interventie</h2>
                        <p>We bieden snelle en doeltreffende interventie bij incidenten, waarbij we indringers afschrikken en de veiligheid van uw objecten en personeel waarborgen.</p>
                    </div>
                    <div className="service-card">
                        <FaUserFriends size={30} className="icon-red" />
                        <h2>Bezoekersbeheer</h2>
                        <p>We implementeren strikte toegangscontrolemaatregelen en bieden bezoekersbeheer om de toegang tot uw objecten te beheren en ongeautoriseerde toegang te voorkomen.</p>
                    </div>
                    <div className="service-card">
                        <FaUserCog size={30} className="icon-red" />
                        <h2>Maatwerkoplossingen</h2>
                        <p>We ontwikkelen op maat gemaakte beveiligingsoplossingen die zijn afgestemd op de specifieke behoeften en kenmerken van uw objecten en omgeving.</p>
                    </div>
                    <div className="service-card">
                        <FaClock size={30} className="icon-red" />
                        <h2>24/7 Bewaking</h2>
                        <p>We bieden continue bewaking en beveiliging van uw objecten, waarbij we paraat staan om te reageren op noodsituaties en onvoorziene gebeurtenissen.</p>
                    </div>
                </div>

                <OfferteFooter />
            </div>
        </div>
    );
}

export default ObjectBeveiliging;
