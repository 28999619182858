import { useEffect } from "react";
import "../About.css"; // Import the same CSS file used in the About component
import OfferteFooter from "../OfferteFooter";

function MissieDoel() {
    useEffect(() => {
        document.title = "Missie en Doel | D&S Security";
    });

    return (
        <div>
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-background-about"></div>
                    <div className="hero-text-about">
                        <h1>Missie en Doel</h1>
                    </div>
                </div>

                <div className='CompanyOverviewSection'> {/* Use the same class name as in the About component */}
                    <h2>Onze Missie</h2>
                    <p>
                        De missie van D&S Security is om hoogwaardige beveiligingsoplossingen te bieden die voldoen aan de
                        behoeften en verwachtingen van onze klanten. We streven ernaar om een veilige omgeving te creëren voor
                        particulieren en bedrijven door middel van innovatieve en effectieve beveiligingsmaatregelen.
                    </p>
                    <h2>Ons Doel</h2>
                    <p>
                        Ons doel is om een toonaangevende speler te worden in de beveiligingsindustrie door continue groei en
                        verbetering. We streven naar klanttevredenheid door het leveren van betrouwbare en professionele
                        diensten, ondersteund door ons deskundige team en geavanceerde technologieën.
                    </p>
                </div>
            </div>
            <OfferteFooter />
        </div>
    );
}

export default MissieDoel;
