import { useEffect } from "react";
import OfferteFooter from "../../OfferteFooter";
import { FaHospital, FaAmbulance, FaUserShield, FaHeartbeat, FaHandsHelping, FaNotesMedical }  from 'react-icons/fa';

function Zorgbeveiliging() {
    useEffect(() => {
        document.title = "Diensten | D&S Security";
    });

    return (
        <div className="zorgbeveiliging-container">
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-section-zorgbeveiliging"></div>
                    <div className="hero-text-about">
                        <h1>Zorgbeveiliging Diensten</h1>
                    </div>
                </div>

                <p className="intro-text">Veiligheid in de zorgsector is van vitaal belang voor zowel patiënten als medisch personeel. Ontdek hieronder waarom onze zorgbeveiligingsdiensten uw beste keuze zijn.</p>

                <div className="service-content">
                    <div className="service-card">
                        <FaHospital size={30} className="icon-red"/>
                        <h2>Ziekenhuisbeveiliging</h2>
                        <p>We bieden uitgebreide beveiligingsdiensten voor ziekenhuizen, inclusief toegangscontrole,
                            bewaking van gevoelige gebieden en snelle respons op noodsituaties.</p>
                    </div>
                    <div className="service-card">
                        <FaAmbulance size={30} className="icon-red"/>
                        <h2>Ambulancebeveiliging</h2>
                        <p>We beschermen ambulances en hun bemanning tijdens het uitvoeren van levensreddende taken,
                            waarbij we ervoor zorgen dat ze veilig en ononderbroken hun werk kunnen doen.</p>
                    </div>
                    <div className="service-card">
                        <FaUserShield size={30} className="icon-red"/>
                        <h2>Patiëntbescherming</h2>
                        <p>We waarborgen de veiligheid en privacy van patiënten, inclusief begeleiding en bescherming
                            tegen ongewenste bezoekers of agressieve situaties.</p>
                    </div>
                    <div className="service-card">
                        <FaHeartbeat size={30} className="icon-red"/>
                        <h2>Medische Apparatuur Bewaking</h2>
                        <p>We bewaken medische apparatuur en faciliteiten om diefstal, vandalisme en storingen te
                            voorkomen, waardoor de continuïteit van de zorg wordt gegarandeerd.</p>
                    </div>
                    <div className="service-card">
                        <FaHandsHelping size={30} className="icon-red"/>
                        <h2>Assistentie aan Medisch Personeel</h2>
                        <p>We bieden ondersteuning aan medisch personeel door te assisteren bij het omgaan met
                            uitdagende situaties en het handhaven van een veilige omgeving voor de zorgverlening.</p>
                    </div>
                    <div className="service-card">
                        <FaNotesMedical size={30} className="icon-red"/>
                        <h2>Medische Dossiers Beveiliging</h2>
                        <p>We waarborgen de vertrouwelijkheid en integriteit van medische dossiers, inclusief
                            bescherming tegen ongeautoriseerde toegang en datalekken.</p>
                    </div>
                </div>

                <OfferteFooter/>
            </div>
        </div>
    );
}

export default Zorgbeveiliging;
